.resources .ant-menu {
  border-right: initial;
  color: #000;
}

.resources .ant-menu-item:hover,
.ant-menu-item-active {
  color: #000;
  font-weight: bold;
}

.resources .ant-menu-item-selected {
  color: #000;
  font-weight: bold;
}

.resources .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fff;
}

.resources .ant-menu-inline .ant-menu-item::after {
  border-right: initial;
}

.resources .ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #000;
  font-weight: bold;
}

.resources .ant-menu-submenu-open {
  font-weight: bold;
}

.resources
  .ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::before {
  background: #000;
}

.resources
  .ant-menu-submenu-inline
  > .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow::after {
  background: #000;
}

.resources .ant-menu-submenu-open .ant-menu-item {
  color: #4d4d4d;
  font-weight: normal;
}

.resources .ant-menu-sub .ant-menu-item-selected {
  color: #4d4d4d;
  font-weight: bold;
}

.resources .ant-menu-submenu-open .ant-menu-item:hover,
.ant-menu-item-active {
  font-weight: bold;
}

.resources .ant-menu-submenu-selected {
  color: #000;
}

.resources .filter-sider {
  background: #fff;
  margin-top: 1em;
  position: sticky;
  top: 9.5em;
}

.resources .filter-bar {
  position: sticky;
  top: -0.1em;
  z-index: 10;
  transition: top 0.25s;
}

.resources .filter-search {
  position: sticky;
  z-index: 10;
  transition: top 0.25s;
}

.resources .loader {
  margin-left: 33%;
  margin-top: 10%;
}

.resources .line {
  margin: 0;
  height: '1px';
}
