.ant-btn {
  line-height: 2.1;
}

.anticon {
  vertical-align: 0.1em;
}

.ant-drawer-body {
  padding: 0;
}

.ant-select-selection__placeholder {
  line-height: 23.75px;
}

.ant-dropdown-trigger {
  margin-left: 0.3em;
  margin-right: 0.3em;
}

.resources-filter {
  background: #fff;
  border-bottom: 1px solid rgba(235, 235, 235, 1);
  border-top: 1px solid rgba(235, 235, 235, 1);
  padding-left: 220px;
  padding-top: 1em;
  padding-bottom: 1em;
  position: sticky;
  top: 4em;
  width: 100%;
  z-index: 10;
}

.button {
  border-radius: 4px;
  width: 10%;
  font-size: 1em;
  height: 2em;
}

.button:hover,
.button:focus {
  background-color: #431c72;
  border-color: #fff;
  color: #fff;
}

.sort-dropdown {
  float: right;
  margin-right: 0.3em;
  line-height: 2;
}

.radio-filter {
  display: block;
  height: 3em;
  line-height: 3em;
}

.radio-container {
  box-shadow: 1px 1px 3px grey;
  text-align: center;
  width: max-content;
  background: #fff;
  padding: 1em 2em 1em 2em;
  text-align: left;
}

.radio-container-sort {
  background: #fff;
  box-shadow: 1px 1px 3px grey;
  padding: 1em 0.5em 1em 1em;
  text-align: left;
}

.searchbar-align-right {
  margin-right: 2em;
  float: right;
  padding-right: 1.5em;
}

.searchbar-filter {
  width: 120%;
}

.title-filter {
  text-align: left;
}

.rfs-category-option {
  text-align: left;
  font-weight: 550;
}

.rfs-subcategory-option {
  text-align: left;
  font-weight: 450;
  padding-left: 1.2em;
}
