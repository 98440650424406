.mb-rd-container {
  padding-top: 2em;
}

.mb-rd-carousel {
  width: 100%;
  height: 256px;
  background: #6a3e9e;
}

.mb-rd-carousel-img {
  width: 100%;
  height: 256px;
}

.mb-rd-block-container {
  margin-left: 1.5em;
  margin-right: 1.5em;
}

.mb-rd-block-1 {
  padding-top: 1em;
  padding-bottom: 1em;
  border-bottom: 2px solid rgba(235, 235, 235, 1);
}

.mb-rd-block-1 .link,
.mb-rd-block-1 span strong {
  color: black;
  font-size: 0.85em;
}

.mb-rd-block-1 .link {
  font-weight: 300;
}

.mb-rd-block-1 .link:hover {
  font-weight: bold;
  text-decoration: none;
}

.mb-rd-header-bar {
  margin-top: 0.8em;
  justify-content: space-between;
  align-items: center;
}

.mb-rd-header-text {
  font-weight: 700;
  font-size: 1.8em;
}

.mb-rd-save-btn {
  position: relative;
  top: -9px;
}

.mb-rd-save-btn:hover {
  height: 42px;
  width: 42px;
  transition: all 0.2s;
}

.mb-rd-rate {
  color: #6a3e9e;
}

.mb-rd-rate .ant-rate-star > div:hover,
.ant-rate-star > div:focus {
  transform: scale(1);
}

.mb-rd-description-container {
  padding-top: 0.8em;
}

.mb-rd-description {
  color: black;
}

.mb-rd-block-2 {
  padding-top: 1.5em;
  padding-bottom: 1em;
  border-bottom: 2px solid rgba(235, 235, 235, 1);
}

.mb-rd-block-title {
  font-weight: 600;
  font-size: 1.2em;
  margin-bottom: 1.5em;
}

.mb-rd-icon-container {
  padding-top: 0.15em;
}

.mb-rd-icon {
  font-size: 1.3em;
}

.mb-rd-phone-icon {
  transform: rotate(90deg);
}

.mb-rd-info-title {
  font-weight: 500;
  font-size: 1.1em;
}

.mb-rd-thin-text {
  font-weight: 350;
}

.mb-rd-schedule {
  padding-top: 1em;
  padding-left: 1.5em;
}

.mb-rd-container .ant-timeline-item-head {
  padding-top: 0.5em;
  padding-bottom: 0em;
  background-color: none;
}

.mb-rd-schedule .ant-timeline-item-tail {
  border-left: 2px dashed #6bdbd0;
  height: calc(100%);
}

.mb-rd-schedule .ant-timeline-item:last-child .ant-timeline-item-tail {
  display: none;
}

.md-rd-schedule-entry {
  padding-left: 2.5em;
}

.mb-rd-schedule-entry-title {
  font-weight: 550;
  font-size: 1.3em;
  color: black;
}

.mb-rd-schedule-text {
  font-weight: 350;
  font-size: 1.1em;
}

.mb-rd-schedule-open-now {
  font-weight: 450;
  font-size: 1.1em;
  color: rgb(47, 209, 47);
}

.mb-rd-schedule-dot {
  display: inline-block;
  background-color: #6bdbd0;
  border-radius: 50%;
  height: 23px;
  width: 23px;
}

.mb-rd-location-info {
  padding-bottom: 0.5em;
}
