@import '~antd/dist/antd.css';

.navigation {
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 5;
  padding-top: 0.5em;
}

.navigation .logo {
  background-color: #fff;
  background-image: url('/asset/icon/logo192.png');
  background-position: center;
  background-size: cover;
  float: left;
  height: 32px;
  margin-top: 0.5em;
  width: 32px;
}

.navigation .ant-menu {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.65);
}

.navigation .ant-menu-horizontal {
  border-bottom: none;
  float: right;
}

.navigation .ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-item:hover {
  border-bottom: none;
  font-weight: 400;
}

.ant-menu-horizontal > .ant-menu-item > a:hover,
.ant-menu-horizontal > .ant-menu-item-selected > a {
  color: rgba(0, 0, 0, 0.65);
}
