.filter-category {
  font-size: 20px;
  padding-top: 0.4em;
  margin-top: 0.4em;
  margin-left: 2em;
  margin-right: 1em;
}

.filter-top {
  margin-top: 1em;
}

.filter-type {
  text-indent: 1em;
  margin-bottom: 0.4em;
  border-width: 0.1em;
}

.apply-button {
  text-align: center;
  margin-top: 1em;
  margin-bottom: 1em;
}

.apply-button .ant-btn-primary {
  background-color: rgb(136, 216, 208);
  border-color: rgb(136, 216, 208);
  text-shadow: none;
}

.ant-menu .down-icon {
  margin-left: 0.5em;
  font-size: 12px;
}

.category-dropdown {
  font-size: 16px;
  margin-left: 25.094px;
}

.filter-title {
  margin-bottom: 0.6em;
}
