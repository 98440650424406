.upload-button {
  background: #6bdbd0 !important;
  color: white !important;
  margin: auto;
  border-radius: 0px !important;
  box-shadow: none !important;
  border: 0px !important;
  border-radius: 4px !important;
}

.crop-container {
  width: 100%;
  height: 200px;
  position: relative;
}

.upload-container {
  padding-bottom: 1em;
}
